import React from "react";
import MenuSport from "./MenuSport";

export const GeneralSport = () => {
  return (
    <div className="p-grid p-fluid dashboard">
      <MenuSport />
      <div className="p-col-12 p-lg-12">Allgemeines zum Sportverein</div>
      <div className="p-col-12 p-lg-12">
        <img
          src="assets/images/logo/sportverein.png"
          alt="bild"
          className="advertising-image"
        />
      </div>
    </div>
  );
};
