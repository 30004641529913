export const calendarLocale = {
  firstDayOfWeek: 1,
  dayNames: [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
  ],
  dayNamesShort: ["SO", "MO", "DI", "MI", "DO", "FR", "SA"],
  dayNamesMin: ["S", "M", "D", "M", "D", "F", "S"],
  monthNames: [
    "Jänner",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ],
  monthNamesShort: [
    "JÄN",
    "FEB",
    "MÄR",
    "APR",
    "MAI",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OKT",
    "NOV",
    "DEC",
  ],
  today: "Heute",
  clear: "Leeren",
  dateFormat: "dd-mm-yy",
  weekHeader: "Sm",
};
