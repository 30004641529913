import React, { useEffect } from "react";

export const useStateWithSessionStorage = (key) => {
  const [value, setValue] = React.useState(
    JSON.parse(sessionStorage.getItem(key)) || null
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, setValue];
};

export const useStateWithSessionStorageNumber = (key) => {
  const [value, setValue] = React.useState(
    JSON.parse(sessionStorage.getItem(key)) || 0
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, setValue];
};

export const useStateWithSessionStorageArray = (key) => {
  const [value, setValue] = React.useState(
    JSON.parse(sessionStorage.getItem(key)) || []
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, setValue];
};
