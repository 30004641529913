import React from "react";
import MenuChurch from "./MenuChurch";

export const GeneralChurch = () => {
  return (
    <div className="p-grid p-fluid dashboard">
      <MenuChurch />
      <div className="p-col-12 p-lg-12">Allgemeines zur Kirche</div>
    </div>
  );
};
