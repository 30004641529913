import { faClock, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import deLocale from "@fullcalendar/core/locales/de";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import is from "is_js";
import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FullCalendar } from "primereact/fullcalendar";
import React, { useEffect, useState } from "react";
import FireBaseService from "../../service/FirebaseService";
import { dateFormat, dateTimeFormat, timeFormat } from "../enums/enums";
import { isArray } from "../util/ArrayUtils";
import { addInlineStyleToImage } from "../util/ImageUtils";
import { isDesktop } from "../util/ScreenUtils";
import { prepareInlineUrls } from "../util/UrlUtils";

export function Events() {
  // const [events, setEvents] = useStateWithSessionStorage("events");
  const [events, setEvents] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [dialogComp, setDialogComp] = useState(null);
  const options = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
    defaultView: isDesktop() ? "dayGridMonth" : "listMonth",
    defaultDate: new Date(),
    header: {
      left: "prev,next, today",
      center: "title",
      right: "dayGridMonth,listMonth",
    },
    buttonText: {
      list: "Liste",
    },
    editable: true,
    locales: [deLocale],
    eventClick: (e) => {
      setDialogComp(e.event);
      setDialog(true);
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await FireBaseService.findAllEvents();
      setEvents(
        data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );
    };
    if (!isArray(events)) {
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function formatEvents() {
    return events.map((event) => {
      const { title } = event;

      let startTime;
      try {
        startTime = moment(event.start.toMillis()).toDate();
      } catch (error) {
        startTime = moment.unix(event.start.seconds).toDate();
      }
      let endTime;
      try {
        endTime = moment(event.end.toMillis()).toDate();
      } catch (error) {
        endTime = moment.unix(event.end.seconds).toDate();
      }

      return {
        title,
        start: startTime,
        end: endTime,
        color: getRandomColor(),
        extendedProps: { ...event },
      };
    });
  }

  function getRandomColor() {
    const colorPool = ["#b02875", "#2185d1", "#19b589", "#abb519", "#ba8116"];
    return colorPool[Math.floor(Math.random() * 5)];
  }

  function handleChange(newValue) {
    setDialog(newValue);
  }

  const result = events.length ? (
    <div className="p-grid p-fluid dashboard">
      <div className="p-col-12 p-lg-12">
        <FontAwesomeIcon icon={faInfo} className="fa-icon-event" /> Nähere Infos
        zum Event findest du durch einen Klick auf Namen
      </div>
      <div className="p-col-12 p-lg-12">
        <FullCalendar
          events={formatEvents()}
          options={options}
          style={{ height: "100% !important" }}
        ></FullCalendar>
        <MyDialog
          visible={dialog}
          event={dialogComp}
          onChange={handleChange}
          className="event-dialog"
        />
      </div>
    </div>
  ) : null;

  return <> {result}</>;
}

const MyDialog = (props) => {
  function handleChange(event) {
    // Here, we invoke the callback with the new value
    props.onChange(false);
  }

  const singleComponent = (start, end) => {
    return (
      <>
        {moment(start).format(dateTimeFormat)} -{" "}
        {moment(end).format(dateTimeFormat)}
      </>
    );
  };

  const combiComponent = (start, end) => {
    return (
      <>
        {moment(start).format(dateFormat)} ({moment(start).format(timeFormat)} -{" "}
        {moment(end).format(timeFormat)})
      </>
    );
  };

  const isSameDay = (start, end) => {
    if (start === null || end === null) {
      return true;
    }
    return start.getDay() === end.getDay();
  };

  const dateComponent = (start, end) => {
    return isSameDay(start, end)
      ? combiComponent(start, end)
      : singleComponent(start, end);
  };

  const iFrame = (value) => {
    return is.not.null(value) && is.not.empty(value) ? (
      <div className="iframe-container">
        <iframe
          src={value}
          className="responsive-iframe"
          title="Flyer"
        ></iframe>
      </div>
    ) : null;
  };

  const externalLink = (value) => {
    return is.not.null(value) && is.not.empty(value) ? (
      <a href={value} title="title" target="_blank" rel="noopener noreferrer">
        <Button label="Link" icon="pi pi-external-link" />
      </a>
    ) : null;
  };

  const prepareDescriptionImgaes = (value) => {
    var result = prepareInlineUrls(addInlineStyleToImage(value));

    return is.not.null(result) ? (
      <p
        dangerouslySetInnerHTML={{
          __html: result,
        }}
      />
    ) : null;
  };

  const dialg = !!props.event ? (
    <Dialog
      header={props.event.title}
      visible={props.visible}
      onHide={handleChange}
    >
      <div className="p-grid p-fluid p-align-start">
        <div className="p-col-12 p-lg-12">
          <span>
            <FontAwesomeIcon icon={faClock} className="fa-icon-event" />
            {dateComponent(props.event.start, props.event.end)}
          </span>
        </div>
        <div className="p-col-12 p-lg-12">
          {prepareDescriptionImgaes(props.event.extendedProps.description)}
        </div>
        <div className="p-col-12 p-lg-12">
          {iFrame(props.event.extendedProps.link)}
        </div>
        <div className="p-col-12 p-lg-12">
          {externalLink(props.event.extendedProps.link)}
        </div>
      </div>
    </Dialog>
  ) : null;

  return <>{dialg}</>;
};
