import React, { useEffect } from "react";
import MenuFF from "./MenuFF";

export const HouseFF = ({ history }) => {
  useEffect(() => {}, []);

  return (
    <div className="p-grid p-fluid dashboard">
      <MenuFF />

      <div className="p-col-12 p-lg-12">
        <h1>Feuerwehrhaus</h1>
      </div>
    </div>
  );
};
