import {
  articleCollectionProvider,
  eventCollectionProvider,
  personCollectionProvider,
} from "../components/config/Db";
import firebase from "../firebase";

export default class FireBaseService {
  static findAllArticles() {
    const data = firebase.db
      .collection(articleCollectionProvider())
      .where("deleted", "==", false)
      .orderBy("createdAt", "desc")
      .get();
    return data;
  }

  static findAllEvents() {
    const data = firebase.db
      .collection(eventCollectionProvider())
      .where("deleted", "==", false)
      .orderBy("createdAt", "desc")
      .get();
    return data;
  }

  static findAllPersons() {
    const data = firebase.db
      .collection(personCollectionProvider())
      .where("deleted", "==", false)
      .orderBy("createdAt", "desc")
      .get();
    return data;
  }

  static findPersonsByTyp(type) {
    const data = firebase.db
      .collection(personCollectionProvider())
      .where("types", "array-contains", type)
      .where("deleted", "==", false)
      .get();
    return data;
  }

  static findArticleById(id) {
    const data = firebase.db
      .collection(articleCollectionProvider())
      .doc(id)
      .get();
    return data;
  }

  static findEventById(id) {
    const data = firebase.db
      .collection(eventCollectionProvider())
      .doc(id)
      .get();
    return data;
  }

  static findPersonById(id) {
    const data = firebase.db
      .collection(personCollectionProvider())
      .doc(id)
      .get();
    return data;
  }

  static findArticleByType(type) {
    const data = firebase.db
      .collection(articleCollectionProvider())
      .where("type", "==", type)
      .where("deleted", "==", false)
      .orderBy("createdAt", "desc")
      .get();
    return data;
  }

  static findConfig() {
    const data = firebase.db
      .collection("config")
      .get()
      .catch((error) => console.log(error));
    return data;
  }

  static updateConfigLastChange() {
    firebase.db
      .collection("config")
      .doc("Zo4GwMBDeejCcYxYbep7")
      .update({ lastChange: firebase.newTimestamp() })
      .catch((error) => {
        console.log("error updating lastChange timestamp" + error);
      });
  }

  static mapSortOderFromClubToPerson(persons, type) {
    persons.forEach((person) => {
      const obj = person.clubs.filter((club) => club.club === type);
      person.sort = obj[0].order;
    });
  }

  static sortPersonByOrderDec(a, b) {
    if (a.sort < b.sort) {
      return 1;
    }
    if (a.sort > b.sort) {
      return -1;
    }
    return 0;
  }
}
