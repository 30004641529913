import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Editor } from "primereact/editor";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../firebase";
import FireBaseService from "../../service/FirebaseService";
import { eventCollectionProvider } from "../config/Db";
import { calendarLocale } from "../util/CalendarLocale";

export const EventDetail = ({ event, docId }) => {
  const [title, setTitle] = React.useState(event.title);
  const [link, setLink] = React.useState(event.link);
  const [description, setDescription] = React.useState(event.description);
  const [start, setStart] = React.useState(event.start.toDate());
  const [end, setEnd] = React.useState(event.end.toDate());
  const [growl, setGrowl] = React.useState();
  const [redirect, setRedirect] = React.useState(false);

  const onDelete = () => {
    firebase.db
      .collection(eventCollectionProvider())
      .doc(docId)
      .update(objToDelete)
      .then(() => {
        showSuccess();
        setRedirect(true);
      })
      .catch((error) => {
        console.log("error updating data" + error);
        showError();
      });

    FireBaseService.updateConfigLastChange();
  };

  const onUpdate = () => {
    const errorList = validate(objToUpdate);
    if (errorList.length > 0) {
      showErrorMulti(errorList);
      return;
    }

    firebase.db
      .collection(eventCollectionProvider())
      .doc(docId)
      .update(objToUpdate)
      .then(() => {
        showSuccess();
      })
      .catch((error) => {
        console.log("error updating data" + error);
        showError();
      });
    FireBaseService.updateConfigLastChange();
  };

  const objToCreate = {
    id: uuidv4().toString(),
    createdBy: firebase.getCurrentUserEmail(),
    createdAt: firebase.newTimestamp(),
    deleted: false,
    start: start,
    end: end,
    title: title,
    description: description,
    link: formatLink(link),
  };

  const objToUpdate = {
    updatedBy: firebase.getCurrentUserEmail(),
    updatedAt: firebase.newTimestamp(),
    deleted: false,
    start: start,
    end: end,
    title: title,
    description: description,
    link: formatLink(link),
  };

  const objToDelete = {
    updatedBy: firebase.getCurrentUserEmail(),
    updatedAt: firebase.newTimestamp(),
    start: start,
    end: end,
    title: title,
    description: description,
    link: formatLink(link),
    deleted: true,
  };

  const onCreate = () => {
    const errorList = validate(objToCreate);
    if (errorList.length > 0) {
      showErrorMulti(errorList);
      return;
    }

    firebase.db
      .collection(eventCollectionProvider())
      .add(objToCreate)
      .then(() => {
        showSuccess();
        setRedirect(true);
      })
      .catch((error) => {
        console.log("error updating data" + error);
        showError();
      });
    FireBaseService.updateConfigLastChange();
  };

  const showSuccess = () => {
    growl.show({
      severity: "success",
      summary: "Super!",
      detail: "Speichern war erfolgreich",
    });
  };

  const showError = () => {
    growl.show({
      severity: "error",
      summary: "Upps - Speichern fehlgeschlafen",
      detail: "Die Beschreibung darf max. 1MB betragen!",
      life: 10000, // 10sec
    });
  };

  const showErrorMulti = (list) => {
    let obj = [];

    list.forEach((error) =>
      obj.push({
        severity: "error",
        summary: "Upps - Prüfung fehlgeschlafen",
        detail: error,
        life: 10000,
      })
    );

    growl.show(obj);
  };

  const button = !!docId ? (
    <>
      <div className="p-col-12 p-lg-10">
        <Button
          icon="pi pi-save"
          label="Updaten und veröffentlichen"
          onClick={onUpdate}
        />
      </div>
      <div className="p-col-12 p-lg-2">
        <Button
          icon="pi pi-trash"
          label="Löschen"
          onClick={onDelete}
          className="p-button-danger"
        />
      </div>
    </>
  ) : (
    <Button
      icon="pi pi-save"
      label="Speichern und veröffentlichen"
      onClick={onCreate}
    />
  );

  const element = redirect ? (
    <Redirect to={`/admin-overview`} />
  ) : (
    <div className="p-grid p-fluid">
      <Growl ref={(el) => setGrowl(el)} />
      <div className="p-col-10 p-lg-10">
        <h1>Administration</h1>
      </div>
      <div className="p-col-2 p-lg-2">
        <Link to={`/admin-overview`}>
          <Button icon="pi pi-arrow-circle-left" label="Zurück zur Übersicht" />
        </Link>
      </div>
      <div className="p-col-12 p-lg-12">
        <h2>Events</h2>
      </div>
      <div className="p-col-12 p-lg-2">Titel</div>
      <div className="p-col-12 p-lg-4">
        <InputText
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Title der Veranstaltung"
        />
      </div>
      <div className="p-col-12 p-lg-6"></div>
      <div className="p-col-12 p-lg-2">Von</div>
      <div className="p-col-12 p-lg-4">
        <Calendar
          value={start}
          onChange={(e) => setStart(e.value)}
          dateFormat="yy-mm-dd"
          showTime={true}
          hourFormat="24"
          locale={calendarLocale}
        />
      </div>
      <div className="p-col-12 p-lg-2 admin-label-right">Bis</div>
      <div className="p-col-12 p-lg-4">
        <Calendar
          value={end}
          onChange={(e) => setEnd(e.value)}
          dateFormat="yy-mm-dd"
          showTime={true}
          hourFormat="24"
          locale={calendarLocale}
        />
      </div>
      <div className="p-col-12 p-lg-2">
        <span>Cloud Link</span>
        <span className="link-tooltip">
          <Button type="button" icon="fas fa-info" tooltip="PDF von Google Drive müssen NICHT umgewandelt werden, Bilder von Google Fotos müssen mit dem Link umgewandelt werden" />
        </span>
      </div>
      <div className="p-col-12 p-lg-4">
        <a
          href="https://www.labnol.org/embed/google/photos/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Link zum URL generieren
        </a>
        <InputText
          value={link}
          onChange={(e) => setLink(e.target.value)}
          tooltip="Hallo"
          placeholder="z.B. Link zum Flyer"
        />
      </div>
      <div className="p-col-12 p-lg-2"></div>
      <div className="p-col-12 p-lg-4"></div>
      <div className="p-col-12 p-lg-12">Beschreibung der Veranstaltung</div>
      <div className="p-col-12 p-lg-12">
        <Editor
          style={{ height: "320px" }}
          value={description}
          onTextChange={(e) => setDescription(e.htmlValue)}
        />
      </div>
      <div className="p-col-12 p-lg-12 p-grid">{button}</div>
    </div>
  );

  return <>{element}</>;
};

const formatLink = (value) => {
  if (!value) {
    return "";
  }
  //return value;

  if (value.includes('drive.google')) {
    return value.substr(0, value.lastIndexOf("/") + 1).concat("preview");
  }
  return value;
};

function validate(event) {
  var result = [];
  if (!event.title) result.push("Titel ist ein Pflichtfeld");
  if (!event.start) result.push("Start Datum ist ein Pflichtfeld");
  if (!event.end) result.push("Ende Datum ist ein Pflichtfeld");
  if (!event.description) result.push("Kurzbeschreibung ist ein Pflichtfeld");

  if (event.start.getTime() === event.end.getTime())
    result.push("Start und Ende dürfen nicht gleich sein.");

  return result;
}
