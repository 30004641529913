import React, { useEffect } from "react";

export const Dashboard = () => {
  // const [config, setConfig] = useStateWithLocalStorage("config");
  // const [configDb, setConfigDb] = useState([]);

  useEffect(() => {
    console.log(
      `${process.env.REACT_APP_NAME} [v${process.env.REACT_APP_VERSION}]`
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12 p-lg-12 dashboard-image-container">
        <img
          src="assets/images/logo/logo.png"
          className="dashboard-image"
          alt="bild"
        />
      </div>
      <div className="p-col-12 p-lg-1 florianihof-walzer">
        <a
          href="https://www.florianihof-walzer.at/"
          title="title"
          target="_blank"
          rel="noopener noreferrer"
        >
          Florianihof Walzer
        </a>
      </div>
      <div className="p-col-12 p-lg-10 dashboard-image-container">
        <img
          src="https://lh3.googleusercontent.com/DPAGXwOEjZPmUDAVC1Dz1xbnAOzBlgzVoQT2d-KIYMSsVyleX2jtTxyNYLiLHyUsP0OR_f_mCakl2nSLAu1b-E9R2w9DP8DXhjkpYU5-Nk12H-0S5MhojksUj7rxdGiRUnUeGL7Y3Q"
          className="dashboard-image"
          alt="bild"
        />
        <p className="dashboard-text">
          großmeiseldorf - weinviertel - niederösterreich - österreich
        </p>
      </div>
      <div className="p-col-12 p-lg-1 "> </div>

      <div className="p-col-12 dashboard-spacer"> </div>

      <div className="p-col-12 p-lg-6 p-md-12 p-sm-12">
        <a
          href="https://www.sparkasse.at/horn/privatkunden"
          title="title"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="assets/images/index/sparkasse.png"
            alt="bild"
            className="advertising-image"
          />
        </a>
      </div>
      <div className="p-col-12 p-lg-6 p-md-12 p-sm-12 advertising-raika">
        <a
          href="https://www.raiffeisen.at/noew/hollabrunn/de/meine-bank/bankstellen/ziersdorf.html"
          title="title"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="assets/images/index/raika.jpg"
            alt="bild"
            className="advertising-image"
          />
        </a>
      </div>
    </div>
  );
};
