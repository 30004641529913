import is from "is_js";

export const addInlineStyleToImage = (value) => {
  var result;
  if (is.not.null(value) && is.not.empty(value)) {
    if (value.includes("<img")) {
      const style = '<img style="max-width: 100%; "';

      result = value.replace(/<img/g, style);
    } else {
      result = value;
    }
  } else {
    result = value;
  }
  return result;
};
