import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FireBaseService from "../../service/FirebaseService";
import { Article } from "../Article";
import { dateFormat } from "../enums/enums";
import MenuBauernbund from "./MenuBauernbund";

export const NewsBauernbund = ({ history }) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await FireBaseService.findArticleByType("BAUERNBUND");
      setArticles(
        data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          date: moment(doc.data().date.toDate()).format(dateFormat),
        }))
      );
    };
    fetchData();
  }, []);

  return (
    <div className="p-grid p-fluid dashboard">
      <MenuBauernbund />

      <div className="p-col-12 p-lg-12">
        <h1>Bauernbund</h1>
      </div>
      {articles.map((value) => {
        return (
          <div className="p-col-12 p-lg-4" key={value.id}>
            <Link key={value.id} to={`/news-article/${value.id}`}>
              <Article key={value.id} {...value} />
            </Link>
          </div>
        );
      })}
    </div>
  );
};
