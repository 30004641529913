import React, { useCallback, useContext } from "react";
import firebase from "../../firebase";
import { withRouter, Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Button } from "primereact/button";

const Login = ({ history }) => {
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      console.log(email);
      try {
        await firebase.login(email.value, password.value);
        history.push("/admin-overview");
      } catch (error) {
        console.log(error.message);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/admin-overview" />;
  }

  return (
    <div className="p-grid p-fluid">
      <form onSubmit={handleLogin}>
        <div className="p-col-12 p-lg-12">
          <span className="p-float-label">
            <input id="email" name="email" type="email" placeholder="Email" />
          </span>
        </div>
        <div className="p-col-12 p-lg-12">
          <span className="p-float-label">
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Passwort"
            />
          </span>
        </div>
        <div className="p-col-12 p-lg-12">
          <Button label="Login" type="submit" />
        </div>
      </form>
    </div>
  );
};

export default withRouter(Login);
