import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, DataTable } from "primereact/datatable";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import FireBaseService from "../../service/FirebaseService";
import { dateFormat } from "../enums/enums";
export const ArticleList = () => {
  const [articles, setArticles] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await FireBaseService.findAllArticles();
      setArticles(
        data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          date: moment(doc.data().date.toDate()).format(dateFormat),
          createdAt: !!doc.data().createdAt
            ? moment(doc.data().createdAt.toDate()).format(dateFormat)
            : null,
          updatedAt: !!doc.data().updatedAt
            ? moment(doc.data().updatedAt.toDate()).format(dateFormat)
            : null,
        }))
      );
    };
    fetchData();
  }, []);

  const actionTemplate = (rowData) => {
    return (
      <div>
        <Link to={`/admin/article/${rowData.id}`}>
          <Button icon="pi pi-search" />
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="p-grid p-fluid">
        <div className="p-col-9 p-lg-9"></div>
        <div className="p-col-3 p-lg-3">
          <Link to={`/admin/article-new`}>
            <Button icon="pi pi-plus-circle" label="Neuer Artikel" />
          </Link>
        </div>
      </div>
      <DataTable
        value={articles}
        paginator={true}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
        currentPageReportTemplate="Einträge {first} bis {last} von {totalRecords} werden angezeigt."
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        responsive={true}
      >
        <Column field="id" header="ID" />
        <Column field="title" header="Titel" />
        <Column field="createdBy" header="Ersteller" />
        <Column field="createdAt" header="am" style={{ width: "100px" }} />
        <Column field="updatedBy" header="Aktualisiert" />
        <Column field="updatedAt" header="am" style={{ width: "100px" }} />
        <Column field="type" header="Typ" />
        <Column body={actionTemplate} style={{ width: "60px" }} />
      </DataTable>

      <div className="p-col-12 p-lg-12">
        <FontAwesomeIcon icon={faInfo} className="fa-icon-event" />
        <a
          href="https://drive.google.com/file/d/1qasCPDeez_AcMne-67isYAW7RCjyHehx/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          Anleitung
        </a>
      </div>
    </>
  );
};

// export default ArticleList;
