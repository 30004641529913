import { Card } from "primereact/card";
import React, { Component } from "react";

export class Article extends Component {
  render() {
    return (
      <Card
        key={this.props.id}
        className="article-card"
        footer={this.props.footer}
        header={<Image {...this.props} />}
      >
        <h1>{this.props.title}</h1>
        {this.props.date}
        <br />
        {this.props.description}
      </Card>
    );
  }
}

class Image extends Component {
  render() {
    let comp;
    if (!!this.props.image) {
      comp = (
        <img
          src={this.props.image}
          alt="bild"
          className={`article-img-${this.props.size}`}
        ></img>
      );
    } else {
      // comp = "text";
    }

    return <div>{comp}</div>;
  }
}
