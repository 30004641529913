import is from "is_js";

export const prepareInlineUrls = (value) => {
  var result;
  if (is.not.null(value) && is.not.empty(value)) {
    if (value.includes('<a href="www')) {
      const httpUrl = '<a href="http://www';

      result = value.replace(/<a href="www/g, httpUrl);
    } else {
      result = value;
    }
  } else {
    result = value;
  }
  return result;
};
