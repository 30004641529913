import { Menubar } from "primereact/menubar";
import React from "react";
import { withRouter } from "react-router";

const MenuMusik = ({ history }) => {
  const items = [
    {
      label: "Allgemein",
      icon: "fas fa-cubes fa-lg",
      command: () => {
        history.push("/musik-allgemein");
      },
    },
    {
      label: "News",
      icon: "far fa-envelope fa-lg",
      command: () => {
        history.push("/musik-news");
      },
    },
    {
      label: "Mitglieder",
      icon: "fas fa-user-friends fa-lg",
      command: () => {
        history.push("/musik-mitglieder");
      },
    },
  ];

  return (
    <div className="p-grid p-fluid dashboard">
      <Menubar model={items} />
    </div>
  );
};

export default withRouter(MenuMusik);
