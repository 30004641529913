import React from "react";
import MenuBauernbund from "./MenuBauernbund";

export const GeneralBauernbund = () => {
  return (
    <div className="p-grid p-fluid dashboard">
      <MenuBauernbund />
      <div className="p-col-12 p-lg-12">Allgemeines zum Bauernbund</div>
    </div>
  );
};
