import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import React from "react";
import { Link, Redirect } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../firebase";
import FireBaseService from "../../service/FirebaseService";
import { articleCollectionProvider } from "../config/Db";
import { articleTypes, sizeList } from "../enums/enums";
import { calendarLocale } from "../util/CalendarLocale";

export const ArticleDetail = ({ article, docId }) => {
  const [title, setTitle] = React.useState(article.title);
  const [author, setAuthor] = React.useState(article.author || "");
  const [type, setType] = React.useState(article.type);
  const [image, setImage] = React.useState(article.image);
  const [imageDetail, setImageDetail] = React.useState(article.detail.image);
  const [link, setLink] = React.useState(article.detail.link);
  const [link2, setLink2] = React.useState(article.detail.link2 || "");
  const [link3, setLink3] = React.useState(article.detail.link3 || "");
  const [linkAuthor, setLinkAuthor] = React.useState(article.detail.linkAuthor);
  const [link2Author, setLink2Author] = React.useState(
    article.detail.link2Author || ""
  );
  const [link3Author, setLink3Author] = React.useState(
    article.detail.link3Author || ""
  );
  const [date, setDate] = React.useState(article.date.toDate());
  const [size, setSize] = React.useState(article.size);
  const [description, setDescription] = React.useState(article.description);
  const [content, setContnet] = React.useState(article.detail.content);
  const [growl, setGrowl] = React.useState();
  const [redirect, setRedirect] = React.useState(false);

  React.useLayoutEffect(() => {
    console.log(
      "This only happens ONCE.  But it still happens AFTER the initial render."
    );
  }, []);

  const onUpdate = () => {
    const errorList = validate(objToUpdate);
    if (errorList.length > 0) {
      showErrorMulti(errorList);
      return;
    }

    firebase.db
      .collection(articleCollectionProvider())
      .doc(docId)
      .set(objToUpdate, { merge: true })
      // .update(objToUpdate)
      .then(() => {
        showSuccess();
      })
      .catch((error) => {
        console.log("error updating data" + error);
        showError();
      });

    FireBaseService.updateConfigLastChange();
  };

  const onDelete = () => {
    firebase.db
      .collection(articleCollectionProvider())
      .doc(docId)
      .update(objToDelete)
      .then(() => {
        showSuccess();
        setRedirect(true);
      })
      .catch((error) => {
        console.log("error updating data" + error);
        showError();
      });

    FireBaseService.updateConfigLastChange();
  };

  const objToCreate = {
    id: uuidv4().toString(),
    createdBy: firebase.getCurrentUserEmail(),
    createdAt: firebase.newTimestamp(),
    deleted: false,
    date: date,
    type: type,
    size: size,
    title: title,
    description: description,
    image: image,
    author: author,
    detail: {
      content: content,
      image: imageDetail,
      text: "text",
      link: link,
      link2: link2,
      link3: link3,
      linkAuthor: linkAuthor,
      link2Author: link2Author,
      link3Author: link3Author,
    },
  };

  const objToUpdate = {
    updatedBy: firebase.getCurrentUserEmail(),
    updatedAt: firebase.newTimestamp(),
    deleted: false,
    date: date,
    type: type,
    size: size,
    title: title,
    description: description,
    image: image,
    author: author,
    detail: {
      content: content,
      image: imageDetail,
      text: "text",
      link: link,
      link2: link2,
      link3: link3,
      linkAuthor: linkAuthor,
      link2Author: link2Author,
      link3Author: link3Author,
    },
  };
  const objToDelete = {
    updatedBy: firebase.getCurrentUserEmail(),
    updatedAt: firebase.newTimestamp(),
    deleted: true,
    date: date,
    type: type,
    size: size,
    title: title,
    description: description,
    image: image,
    author: author,
    detail: {
      content: content,
      image: imageDetail,
      text: "text",
      link: link,
      link2: link2,
      link3: link3,
      linkAuthor: linkAuthor,
      link2Author: link2Author,
      link3Author: link3Author,
    },
  };

  const onCreate = () => {
    const errorList = validate(objToCreate);
    if (errorList.length > 0) {
      showErrorMulti(errorList);
      return;
    }

    firebase.db
      .collection(articleCollectionProvider())
      .add(objToCreate)
      .then(() => {
        showSuccess();
        setRedirect(true);
      })
      .catch((error) => {
        console.log("error updating data" + error);
        showError();
      });
    FireBaseService.updateConfigLastChange();
  };

  const showSuccess = () => {
    growl.show({
      severity: "success",
      summary: "Super!",
      detail: "Speichern war erfolgreich",
    });
  };

  const showError = () => {
    growl.show({
      severity: "error",
      summary: "Upps - Prüfung fehlgeschlafen",
      detail: "Die Beschreibung darf max. 1MB betragen!",
      life: 10000, // 10sec
    });
  };

  const showErrorMulti = (list) => {
    let obj = [];

    list.forEach((error) =>
      obj.push({
        severity: "error",
        summary: "Upps - Prüfung fehlgeschlafen",
        detail: error,
        life: 10000,
      })
    );

    growl.show(obj);
  };

  const button = !!docId ? (
    <>
      <div className="p-col-12 p-lg-10">
        <Button
          icon="pi pi-save"
          label="Updaten und veröffentlichen"
          onClick={onUpdate}
        />
      </div>
      <div className="p-col-12 p-lg-2">
        <Button
          icon="pi pi-trash"
          label="Löschen"
          onClick={onDelete}
          className="p-button-danger"
        />
      </div>
    </>
  ) : (
      <Button
        icon="pi pi-save"
        label="Speichern und veröffentlichen"
        onClick={onCreate}
      />
    );

  const element = redirect ? (
    <Redirect to={`/admin-overview`} />
  ) : (
      <div className="p-grid p-fluid">
        <Growl ref={(el) => setGrowl(el)} />
        <div className="p-col-10 p-lg-10">
          <h1>Administration</h1>
        </div>
        <div className="p-col-2 p-lg-2">
          <Link to={`/admin-overview`}>
            <Button icon="pi pi-arrow-circle-left" label="Zurück zur Übersicht" />
          </Link>
        </div>
        <div className="p-col-12 p-lg-12">
          <h2>Allgemein - Artikel</h2>
        </div>
        <div className="p-col-12 p-lg-2">Titel</div>
        <div className="p-col-12 p-lg-4">
          <InputText
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title des Artikels"
          />
        </div>
        <div className="p-col-12 p-lg-2 admin-label-right">Anzeige bei</div>
        <div className="p-col-12 p-lg-4">
          <Dropdown
            value={type}
            options={articleTypes}
            onChange={(e) => {
              setType(e.value);
            }}
            placeholder="--- bitte wählen ---"
          />
        </div>
        <div className="p-col-12 p-lg-2">Datum</div>
        <div className="p-col-12 p-lg-4">
          <Calendar
            value={date}
            onChange={(e) => setDate(e.value)}
            dateFormat="yy-mm-dd"
            locale={calendarLocale}
          />
        </div>
        <div className="p-col-12 p-lg-2 admin-label-right">Bild Größe</div>
        <div className="p-col-12 p-lg-4">
          <SelectButton
            value={size}
            options={sizeList}
            onChange={(e) => setSize(e.value)}
          />
        </div>
        <div className="p-col-12 p-lg-2">Kurzbeschreibung</div>
        <div className="p-col-12 p-lg-4">
          <InputTextarea
            rows={5}
            cols={30}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            autoResize={true}
            placeholder="Die Kurzbeschreibung ist in den News ersichtlich und sollte daher kurz und prägnant gehalten sein"
          />
        </div>
        <div className="p-col-12 p-lg-2 admin-label-right">News Bild</div>
        <div className="p-col-12 p-lg-4">
          <a
            href="https://www.labnol.org/embed/google/photos/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Link zum URL generieren
        </a>
          <br />
          <InputText
            value={image}
            onChange={(e) => setImage(e.target.value)}
            placeholder="Bild wird bei den News dargestellt"
          />
        </div>
        <div className="p-col-12 p-lg-2">Autor</div>
        <div className="p-col-12 p-lg-4">
          <InputText
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            placeholder="Verfasser des Artikels"
          />
        </div>
        <div className="p-col-12 p-lg-6"></div>
        <div className="p-col-12 p-lg-12">
          <h2>Detail - Artikel</h2>
        </div>
        <div className="p-col-12 p-lg-2">
          Link zu Cloud Fotos (Google, Amazon, ...)
      </div>
        <div className="p-col-12 p-lg-4">
          <InputText
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="Link zu Cloud Album"
          />
          <InputText
            value={linkAuthor}
            onChange={(e) => setLinkAuthor(e.target.value)}
            placeholder="Name des Fotografen"
          />
          <br />
          <br />
          <InputText
            value={link2}
            onChange={(e) => setLink2(e.target.value)}
            placeholder="Link zu Cloud Album"
          />
          <InputText
            value={link2Author}
            onChange={(e) => setLink2Author(e.target.value)}
            placeholder="Name des Fotografen"
          />
          <br />
          <br />
          <InputText
            value={link3}
            onChange={(e) => setLink3(e.target.value)}
            placeholder="Link zu Cloud Album"
          />
          <InputText
            value={link3Author}
            onChange={(e) => setLink3Author(e.target.value)}
            placeholder="Name des Fotografen"
          />
        </div>
        <div className="p-col-12 p-lg-2 admin-label-right">Artikel Bild</div>
        <div className="p-col-12 p-lg-4">
          <a
            href="https://www.labnol.org/embed/google/photos/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Link zum URL generieren
        </a>
          <br />
          <InputText
            value={imageDetail}
            onChange={(e) => setImageDetail(e.target.value)}
            placeholder="Bild wird in einem Artikel dargestellt"
          />
        </div>
        <div className="p-col-12 p-lg-12">Inhalt des Beitrags</div>
        <div className="p-col-12 p-lg-12">
          <Editor
            style={{ height: "320px" }}
            value={content}
            onTextChange={(e) => setContnet(e.htmlValue)}
          />
        </div>
        <div className="p-col-12 p-lg-12 p-grid">{button}</div>
      </div>
    );

  return <>{element}</>;
};

function validate(article) {
  var result = [];
  if (!article.title) result.push("Titel ist ein Pflichtfeld");
  if (!article.type) result.push("Art ist ein Pflichtfeld");
  if (!article.date) result.push("Datum ist ein Pflichtfeld");
  if (!article.size) result.push("Artikel Größe ist ein Pflichtfeld");
  if (!article.description) result.push("Kurzbeschreibung ist ein Pflichtfeld");
  if (!article.detail.content)
    result.push("Inhalt des Beitrags ist ein Pflichtfeld");

  return result;
}
