import React from "react";
import MenuJugend from "./MenuJugend";

export const GeneralJugend = () => {
  return (
    <div className="p-grid p-fluid dashboard">
      <MenuJugend />
      <div className="p-col-12 p-lg-12">Allgemeines zur Jugend</div>
    </div>
  );
};
