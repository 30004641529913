import { InputText } from "primereact/inputtext";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

export class AppTopbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      styleclass: this.props.styleclass,
    };
  }

  static defaultProps = {
    onToggleMenu: null,
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.setState((state, props) => ({
      styleclass: props.styleclass,
    }));
  }

  render() {
    return (
      <div className={this.props.styleclass}>
        <button
          className="p-link layout-menu-button"
          onClick={this.props.onToggleMenu}
        >
          <span className="pi pi-bars" />
        </button>
        <div className="layout-topbar-icons">
          <span className="layout-topbar-search">
            <InputText type="text" placeholder="Suchen" />
            <span className="layout-topbar-search-icon pi pi-search" />
          </span>
          <Link to="/events">
            <button className="p-link">
              <span className="layout-topbar-item-text">Events</span>
              <span className="layout-topbar-icon far fa-calendar-alt fa-2x" />
            </button>
          </Link>
          <Link to="/news">
            <button className="p-link">
              <span className="layout-topbar-item-text">News</span>
              <span className="layout-topbar-icon far fa-envelope fa-2x" />
            </button>
          </Link>
        </div>
      </div>
    );
  }
}
