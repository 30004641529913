import { default as React } from "react";

export function Contact() {
  return (
    <div className="p-grid p-fluid dashboard">
      <div className="p-col-12 p-lg-12">
        <h1>Kontakt</h1>
      </div>
      <div className="p-col-12 p-lg-12">Infos folgen...</div>
      <div className="p-col-12 p-lg-12">
        <div className="iframe-container">
          <iframe
            title="Karte"
            width="425"
            height="350"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://www.openstreetmap.org/export/embed.html?bbox=15.861339569091799%2C48.52288638193795%2C15.89052200317383%2C48.53885799728131&amp;layer=mapnik&amp;marker=48.530872819330654%2C15.875930786132812"
            style={{ border: "1px solid black" }}
            className="responsive-iframe"
          ></iframe>
          <br />
        </div>
        <small>
          <a
            href="https://www.openstreetmap.org/?mlat=48.5309&amp;mlon=15.8759#map=15/48.5309/15.8759"
            target="_blank"
            rel="noopener noreferrer"
          >
            Größere Karte anzeigen
          </a>
        </small>
      </div>
    </div>
  );
}
