import is from "is_js";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Rating } from "primereact/rating";
import React, { useEffect, useState } from "react";
import FileBase64 from "react-file-base64";
import { Link, Redirect } from "react-router-dom";
import firebase from "../../firebase";
import FireBaseService from "../../service/FirebaseService";
import { personCollectionProvider } from "../config/Db";
import { articleTypes } from "../enums/enums";
import { isArray } from "../util/ArrayUtils";
import { calendarLocale } from "../util/CalendarLocale";
export const PersonDetail = ({ person, docId }) => {
  const [birthday, setBirthday] = useState(person.birthday?.toDate());
  const [firstname, setFirstname] = useState(person.firstname);
  const [lastname, setLastname] = useState(person.lastname);
  const [preTitle, setPreTitle] = useState(person.preTitle);
  const [email, setEmail] = useState(person.email);
  const [phone, setPhone] = useState(person.phone);
  const [image, setImage] = useState(person.image);
  const [clubs, setClubs] = useState(person.clubs);

  const [growl, setGrowl] = useState();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => { }, []);

  const onUpload = (picture) => {
    if (is.not.undefined(picture) && is.not.null(picture) && (picture.type === "image/jpeg" || picture.type === "image/png")) {
      setImage(picture.base64);
    } else {
      setImage("");
      alert("not supported - take an image!");
    }
  };

  const addClub = (value) => {
    setClubs(value);
  };

  const removeClub = (value) => {
    setClubs(value);
  };

  const changeValue = (value, index) => {
    // setClubs(value);
    let newArray = [...clubs];
    newArray[index] = { ...newArray[index], club: value };
    setClubs(newArray);
  };

  const changePosition = (value, index) => {
    let newArray = [...clubs];
    newArray[index] = { ...newArray[index], position: value };
    setClubs(newArray);
  };

  const changeDescription = (value, index) => {
    let newArray = [...clubs];
    newArray[index] = { ...newArray[index], description: value };
    setClubs(newArray);
  };

  const changeOrder = (value, index) => {
    setClubs(value);
  };

  const onCreate = () => {
    const errorList = validate(objToCreate);
    if (errorList.length > 0) {
      showErrorMulti(errorList);
      return;
    }

    firebase.db
      .collection(personCollectionProvider())
      .add(objToCreate)
      .then(() => {
        showSuccess();
        setRedirect(true);
      })
      .catch((error) => {
        console.log("error updating data" + error);
        showError();
      });
    FireBaseService.updateConfigLastChange();
  };

  const onUpdate = () => {
    const errorList = validate(objToUpdate);
    if (errorList.length > 0) {
      showErrorMulti(errorList);
      return;
    }

    firebase.db
      .collection(personCollectionProvider())
      .doc(docId)
      .update(objToUpdate)
      .then(() => {
        showSuccess();
      })
      .catch((error) => {
        console.log("error updating data" + error);
        showError();
      });

    FireBaseService.updateConfigLastChange();
  };

  const onDelete = () => {
    firebase.db
      .collection(personCollectionProvider())
      .doc(docId)
      .update(objToDelete)
      .then(() => {
        showSuccess();
        setRedirect(true);
      })
      .catch((error) => {
        console.log("error updating data" + error);
        showError();
      });

    FireBaseService.updateConfigLastChange();
  };

  const mapClubsToTypes = () => {
    const types = [];
    clubs.map((club) => types.push(club.club));
    return types;
  };

  const showSuccess = () => {
    growl.show({
      severity: "success",
      summary: "Super!",
      detail: "Speichern war erfolgreich",
    });
  };

  const showError = () => {
    growl.show({
      severity: "error",
      summary: "Upps - Prüfung fehlgeschlafen",
      detail: "Da ist ein Fehler passiert :/",
      life: 10000, // 10sec
    });
  };

  const showErrorMulti = (list) => {
    let obj = [];

    list.forEach((error) =>
      obj.push({
        severity: "error",
        summary: "Upps - Prüfung fehlgeschlafen",
        detail: error,
        life: 10000,
      })
    );

    growl.show(obj);
  };

  const objToCreate = {
    createdBy: firebase.getCurrentUserEmail(),
    createdAt: firebase.newTimestamp(),
    birthday: birthday,
    firstname: firstname,
    lastname: lastname,
    preTitle: preTitle,
    image: image,
    email: email,
    phone: phone,
    clubs: clubs,
    deleted: false,
    types: mapClubsToTypes(),
  };

  const objToUpdate = {
    updatedBy: firebase.getCurrentUserEmail(),
    updatedAt: firebase.newTimestamp(),
    birthday: birthday,
    firstname: firstname,
    lastname: lastname,
    preTitle: preTitle,
    image: image,
    email: email,
    phone: phone,
    clubs: clubs,
    types: mapClubsToTypes(),
  };

  const objToDelete = {
    deleted: true,
    updatedBy: firebase.getCurrentUserEmail(),
    updatedAt: firebase.newTimestamp(),
    birthday: birthday,
    firstname: firstname,
    lastname: lastname,
    preTitle: preTitle,
    image: image,
    email: email,
    phone: phone,
    clubs: clubs,
    types: mapClubsToTypes(),
  };

  const button = !!docId ? (
    <>
      <div className="p-col-12 p-lg-10">
        <Button
          icon="pi pi-save"
          label="Updaten und veröffentlichen"
          onClick={onUpdate}
        />
      </div>
      <div className="p-col-12 p-lg-2">
        <Button
          icon="pi pi-trash"
          label="Löschen"
          onClick={onDelete}
          className="p-button-danger"
        />
      </div>
    </>
  ) : (
    <Button
      icon="pi pi-save"
      label="Speichern und veröffentlichen"
      onClick={onCreate}
    />
  );

  const element = redirect ? (
    <Redirect to={`/admin-overview`} />
  ) : (
    <div className="p-grid p-fluid">
      <Growl ref={(el) => setGrowl(el)} />
      <div className="p-col-10 p-lg-10">
        <h1>Administration</h1>
      </div>
      <div className="p-col-2 p-lg-2">
        <Link to={`/admin-overview`}>
          <Button icon="pi pi-arrow-circle-left" label="Zurück zur Übersicht" />
        </Link>
      </div>
      <div className="p-col-12 p-lg-12">
        <h2>Person</h2>
      </div>
      <div className="p-col-12 p-lg-2">Vorname</div>
      <div className="p-col-12 p-lg-4">
        <InputText
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        />
      </div>
      <div className="p-col-12 p-lg-2 admin-label-right">Nachname</div>
      <div className="p-col-12 p-lg-4">
        <InputText
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />
      </div>
      <div className="p-col-12 p-lg-2">Titel</div>
      <div className="p-col-12 p-lg-4">
        <InputText
          value={preTitle}
          onChange={(e) => setPreTitle(e.target.value)}
        />
      </div>
      <div className="p-col-12 p-lg-2 admin-label-right">Geburtsdatum</div>
      <div className="p-col-12 p-lg-4">
        <Calendar
          value={birthday}
          onChange={(e) => setBirthday(e.value)}
          dateFormat="yy-mm-dd"
          locale={calendarLocale}
        />
      </div>
      <div className="p-col-12 p-lg-2">Email</div>
      <div className="p-col-12 p-lg-4">
        <InputText value={email} onChange={(e) => setEmail(e.target.value)} />
      </div>
      <div className="p-col-12 p-lg-2 admin-label-right">Telefon</div>
      <div className="p-col-12 p-lg-4">
        <InputText value={phone} onChange={(e) => setPhone(e.target.value)} />
      </div>
      <div className="p-col-12 p-lg-2">Avatar</div>
      <div className="p-col-12 p-lg-2">
        <FileBase64 multiple={false} onDone={onUpload} />
      </div>
      <div className="p-col-12 p-lg-2">
        <img src={image} alt="bild" className="admin-person-image" />
      </div>
      <div className="p-col-12 p-lg-12">
        <h2>zugehörige Vereine</h2>
      </div>
      <div className="p-col-12 p-lg-12 p-grid">
        <Clubs
          clubs={clubs}
          onAdd={addClub}
          onRemove={removeClub}
          changeType={changeValue}
          changePosition={changePosition}
          changeOrder={changeOrder}
          changeDescription={changeDescription}
        />
      </div>
      <div className="p-col-12 p-lg-12 p-grid">{button}</div>
    </div>
  );

  return <>{element}</>;
};

const Clubs = (props) => {
  const addButton = () => {
    return (
      <>
        <Button
          label="Verein zur Person hinzufügen"
          icon="pi pi-plus"
          onClick={(e) => addClub(e)}
        />
      </>
    );
  };
  const removeButton = (index) => (
    <Button icon="pi pi-minus" onClick={(e) => removeClub(e, index)} />
  );

  const addClub = (event) => {
    const array = props.clubs;
    array.push({
      club: "",
      position: "",
      order: 1,
    });
    props.onAdd(array);
  };

  const removeClub = (event, index) => {
    const array = props.clubs;
    array.splice(index, 1);
    props.onRemove(array);
  };

  const changeType = (value, index) => {
    // const array = props.clubs;
    // array[index].club = value;
    // props.changeType(array);
    props.changeType(value, index);
  };

  const changePosition = (value, index) => {
    props.changePosition(value, index);
  };
  const changeDescription = (value, index) => {
    props.changeDescription(value, index);
  };

  const changeOrder = (value, index) => {
    // props.changeOrder(value, index);
    const array = props.clubs;
    array[index].order = value;
    props.changeType(array);
  };

  if (!isArray(props.clubs)) {
    return addButton();
  }

  return (
    <>
      <div className="p-col-12 p-lg-10"></div>
      <div className="p-col-12 p-lg-2">{addButton()}</div>
      <div className="p-col-12 p-lg-12">
        <dl>
          {props.clubs.map((club, index) => {
            return (
              <dt key={index}>
                <div className="p-col-12 p-lg-12 p-grid">
                  <div className="p-col-12 p-lg-1">Verein</div>
                  <div className="p-col-12 p-lg-3">
                    <Dropdown
                      value={club.club}
                      options={articleTypes}
                      onChange={(e) => {
                        changeType(e.value, index);
                      }}
                      placeholder="--- bitte wählen ---"
                    />
                  </div>
                  <div className="p-col-12 p-lg-1">Funktion / Beschreibung</div>
                  <div className="p-col-12 p-lg-1">
                    <InputText
                      value={club.position}
                      onChange={(e) => {
                        changePosition(e.target.value, index);
                      }}
                    />
                  </div>
                  <div className="p-col-12 p-lg-2">
                    <InputTextarea
                      value={club.description}
                      rows={5}
                      // autoResize={true}
                      onChange={(e) => {
                        changeDescription(e.target.value, index);
                      }}
                    />
                  </div>
                  <div className="p-col-12 p-lg-1">Anzeige Reihenfolge</div>
                  <div className="p-col-12 p-lg-1">
                    <Rating
                      value={club.order}
                      cancel={false}
                      onChange={(e) => {
                        changeOrder(e.value, index);
                      }}
                    />
                  </div>
                  <div className="p-col-12 p-lg-1"> {club.order}</div>
                  <div className="p-col-12 p-lg-1">{removeButton(index)}</div>
                </div>
              </dt>
            );
          })}
        </dl>
      </div>
    </>
  );
};

function validate(person) {
  var result = [];
  if (!person.firstname) result.push("Vorname ist ein Pflichtfeld");
  if (!person.lastname) result.push("Nachname ist ein Pflichtfeld");

  if (isArray(person.clubs)) {
    person.clubs.forEach((club) => {
      if (!club.club) result.push("Verein ist ein Pflichtfeld");
      if (!club.position) result.push("Position ist ein Pflichtfeld");
      if (!club.order) result.push("Anzeige Reihenfolge ist ein Pflichtfeld");
    });
  }

  return result;
}
