import React from "react";
import { Button } from "primereact/button";
import firebase from "../../firebase";

function Logout() {
  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12 p-lg-12">
        <Button label="Logout" onClick={() => firebase.logout()} />
      </div>
    </div>
  );
}

export default Logout;
