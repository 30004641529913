import React from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
//import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import WebFont from "webfontloader";
// import "../node_modules/font-awesome/css/font-awesome.min.css";
import App from "./App";
import { AuthProvider } from "./components/context/AuthContext";
import ScrollToTop from "./ScrollToTop";

WebFont.load({
  google: {
    families: ["Modak", "Titillium Web:300,400,700", "sans-serif"],
  },
});

ReactDOM.render(
  <AuthProvider>
    <BrowserRouter>
      <ScrollToTop>
        <App></App>
      </ScrollToTop>
    </BrowserRouter>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
