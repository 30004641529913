import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FireBaseService from "../service/FirebaseService";
import { Article } from "./Article";
import { dateFormat } from "./enums/enums";
import { isArray } from "./util/ArrayUtils";

export function News() {
  // const [news, setNews] = useStateWithLocalStorage("news");
  // const [news, setNews] = useStateWithSessionStorage("news");
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await FireBaseService.findAllArticles();
      setNews(
        data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          date: moment(doc.data().date.toDate()).format(dateFormat),
        }))
      );
    };

    if (!isArray(news)) {
      fetchData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const result = isArray(news) ? (
    <div className="p-grid p-fluid dashboard">
      {news
        .filter((value) => value.type !== "COMPANY")
        .filter((value) => value.type !== "GEMEINDE")
        .map((value) => {
          return (
            <div className="p-col-12 p-lg-4" key={value.id}>
              <Link key={value.id} to={`/news-article/${value.id}`}>
                <Article key={value.id} {...value} />
              </Link>
            </div>
          );
        })}
    </div>
  ) : null;

  return <> {result}</>;
}
