import React from "react";
import MenuJugend from "./MenuDorferneuerung";

export const GeneralDorferneuerung = () => {
  return (
    <div className="p-grid p-fluid dashboard">
      <MenuJugend />
      <div className="p-col-12 p-lg-12">Allgemeines zur Dorferneuerung</div>
    </div>
  );
};
