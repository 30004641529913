export const articleTypes = [
  { label: "Allgemein", value: "ALLGEMEIN" },
  { label: "Feuerwehr", value: "FEUERWEHR" },
  { label: "Musikverein", value: "MUSIK" },
  { label: "Sportverein", value: "SPORT" },
  { label: "Jugend", value: "JUGEND" },
  { label: "Betriebe", value: "COMPANY" },
  { label: "Geburten & Sterbefälle", value: "BIRTH_DEATH" },
  { label: "Dorferneuerung", value: "DORFERNEUERUNG" },
  { label: "Pfarre", value: "CHURCH" },
  { label: "Gemeinde", value: "GEMEINDE" },
  { label: "Kameradschaftsbund", value: "KAMERADE" },
  { label: "Bauernbund", value: "BAUERNBUND" },
];

export const sizeList = [
  { label: "S", value: "S" },
  { label: "M", value: "M" },
  { label: "L", value: "L" },
];

export const dateFormat = "DD.MM.YYYY";
export const dateTimeFormat = "DD.MM.YYYY HH:mm";
export const timeFormat = "HH:mm";

const enums = () => null;

export default enums;
