import moment from "moment";
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import FireBaseService from "../service/FirebaseService";
import { dateFormat } from "./enums/enums";
import { addInlineStyleToImage } from "./util/ImageUtils";
import { prepareInlineUrls } from "./util/UrlUtils";

export function ArticleText(props) {
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const {
      match: { params },
    } = props;
    let ignore = false;

    if (!!params && !!params.id) {
      // fetch data from firestore
      const fetchData = async () => {
        const doc = await FireBaseService.findArticleById(params.id);
        if (!ignore) {
          setArticle(doc.data());
        }
      };
      fetchData();
      return () => {
        ignore = true;
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const link = (link, author, className, index) => {
    if (!link) {
      return null;
    }
    return (
      <a href={link} title="title" target="_blank" rel="noopener noreferrer">
        <Button
          label={`Weitere Fotos - ${author === null ? '' : author}`}
          icon="pi pi-external-link"
          className={className}
        />
      </a>
    );
  };

  let comp;

  if (!!article) {
    comp = (
      <div className="p-grid p-fluid p-align-start">
        <div className="p-col-12 p-lg-12 ">
          <h1>
            {article.title} [{moment(article.date.toDate()).format(dateFormat)}]
          </h1>
        </div>
        <div className="p-col-12 p-lg-4">
          <img
            src={article.detail.image}
            alt="bild"
            className="article-detail-img"
          ></img>
          <div className="p-col-12 p-lg-12 p-grid">
            <div className="p-col-12">
              {link(
                article.detail.link,
                article.detail.linkAuthor,
                "p-button-rounded",
                1
              )}
            </div>
            <div className="p-col-12">
              {link(
                article.detail.link2,
                article.detail.link2Author,
                "p-button-success p-button-rounded",
                2
              )}
            </div>
            <div className="p-col-12">
              {link(
                article.detail.link3,
                article.detail.link3Author,
                "p-button-warning p-button-rounded",
                3
              )}
            </div>
            <div className="p-col-12 p-lg-2">Autor:</div>
            <div className="p-col-12 p-lg-10">{article.author === null ? '' : article.author}</div>
          </div>
        </div>
        <div className="p-col-12 p-lg-8">
          <p
            dangerouslySetInnerHTML={{
              __html: prepareInlineUrls(
                addInlineStyleToImage(article.detail.content)
              ),
            }}
          />
        </div>
      </div>
    );
  } else {
  }
  return <> {comp}</>;
}
