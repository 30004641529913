import React, { useEffect, useState } from "react";
import FireBaseService from "../../service/FirebaseService";
import { Member } from "../Member";
import { isArray } from "../util/ArrayUtils";
import MenuChurch from "./MenuChurch";

export const MemberChurch = ({ history }) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const dataDB = await FireBaseService.findPersonsByTyp("CHURCH");

      // Promise.all is important - otherwise the following sortDate function doesn't work
      const data = await Promise.all(
        dataDB.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }))
      );

      sortData(data);
      setMembers(data);
    };

    const sortData = (persons) => {
      FireBaseService.mapSortOderFromClubToPerson(persons, "CHURCH");
      persons.sort(FireBaseService.sortPersonByOrderDec);
    };

    fetchData();
  }, []);

  const result = () => {
    if (!isArray(members)) {
      return <MenuChurch />;
    }

    return (
      <div className="p-grid">
        <MenuChurch />
        <Member members={members} type="CHURCH"></Member>
      </div>
    );
  };

  return <>{result()}</>;
};
