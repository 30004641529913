import React from "react";
import MenuMusik from "./MenuMusik";

export const GeneralMusik = () => {
  return (
    <div className="p-grid p-fluid dashboard">
      <MenuMusik />
      <div className="p-col-12 p-lg-12">Allgemeines zum Musikverein</div>
    </div>
  );
};
