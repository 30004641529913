import firebase from "firebase";
import "firebase/auth";
import "firebase/firebase-firestore";

// Your web app's Firebase configuration
const config = {
  apiKey: "AIzaSyCPf8fH2870uq2uQtoe1NPWFALj0PRLXFk",
  authDomain: "gmd-app-c35e1.firebaseapp.com",
  databaseURL: "https://gmd-app-c35e1.firebaseio.com",
  projectId: "gmd-app-c35e1",
  storageBucket: "gmd-app-c35e1.appspot.com",
  messagingSenderId: "1083097104317",
  appId: "1:1083097104317:web:24651cd3acc8135b545cd4",
  measurementId: "G-WDZGP0ZYF6",
};

class Firebase {
  constructor() {
    // Initialize Firebase
    firebase.initializeApp(config);
    firebase.analytics();
    this.auth = firebase.auth();
    this.db = firebase.firestore();
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut();
  }

  isInitialized() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged();
    });
  }

  getCurrentUsername() {
    return this.auth.currentUser;
  }

  getCurrentUserEmail() {
    return this.auth.currentUser.email;
  }

  newTimestamp() {
    var date = firebase.firestore.Timestamp.fromDate(new Date());
    return date;
  }
}

export default new Firebase();
