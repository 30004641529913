import React, { useEffect, useState } from "react";
import firebase from "../../firebase";
import FireBaseService from "../../service/FirebaseService";
import { ArticleDetail } from "./ArticleDetail";
import { EventDetail } from "./EventDetail";
import { PersonDetail } from "./PersonDetail";

function AdminArticleUpdate(props) {
  const [article, setArticle] = useState();
  const [docId, setDocId] = useState();

  useEffect(() => {
    const {
      match: { params },
    } = props;
    let ignore = false;
    if (!!params && !!params.id) {
      console.log(params.id);
      const fetchData = async () => {
        // fetch data from firestore
        const doc = await FireBaseService.findArticleById(params.id);
        if (!ignore) {
          setArticle(doc.data());
          setDocId(doc.id);
        }
      };
      fetchData();
      return () => {
        ignore = true;
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const detail = !!article ? (
    <ArticleDetail article={article} docId={docId} />
  ) : null;
  return <>{detail}</>;
}

function AdminArticleCreate() {
  const [article, setArticle] = useState();

  useEffect(() => {
    // create dummy object
    const a = {
      date: firebase.newTimestamp(),
      image: null,
      createdBy: null,
      createdAt: null,
      updatedBy: null,
      updatedAt: null,
      deleted: false,
      detail: {
        image: null,
        link: null,
        linkAuthor: null,
        link2: null,
        link2Author: null,
        link3: null,
        link3Author: null,
      },
    };
    setArticle(a);
  }, []);

  const detail = !!article ? <ArticleDetail article={article} /> : null;
  return <>{detail}</>;
}

// EVENTS

function AdminEventUpdate(props) {
  const [event, setEvent] = useState();
  const [docId, setDocId] = useState();

  useEffect(() => {
    const {
      match: { params },
    } = props;
    let ignore = false;

    if (!!params && !!params.id) {
      console.log(params.id);

      // fetch data from firestore
      const fetchData = async () => {
        const doc = await FireBaseService.findEventById(params.id);
        if (!ignore) {
          setEvent(doc.data());
          setDocId(doc.id);
        }
      };
      fetchData();
      return () => {
        ignore = true;
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const detail = !!event ? <EventDetail event={event} docId={docId} /> : null;
  return <>{detail}</>;
}

function AdminEventCreate() {
  const [event, setEvent] = useState();

  useEffect(() => {
    // create dummy object
    const a = {
      id: null,
      createdBy: null,
      createdAt: null,
      updatedBy: null,
      updatedAt: null,
      deleted: false,
      start: firebase.newTimestamp(),
      end: firebase.newTimestamp(),
      title: null,
      description: null,
      link: null,
    };
    setEvent(a);
  }, []);

  const detail = !!event ? <EventDetail event={event} /> : null;
  return <>{detail}</>;
}

// PERSON

function AdminPersonUpdate(props) {
  const [person, setPerson] = useState();
  const [docId, setDocId] = useState();

  useEffect(() => {
    const {
      match: { params },
    } = props;
    let ignore = false;

    if (!!params && !!params.id) {
      console.log(params.id);

      // fetch data from firestore
      const fetchData = async () => {
        const doc = await FireBaseService.findPersonById(params.id);
        if (!ignore) {
          setPerson(doc.data());
          setDocId(doc.id);
        }
      };
      fetchData();
      return () => {
        ignore = true;
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const detail = !!person ? (
    <PersonDetail person={person} docId={docId} />
  ) : null;
  return <>{detail}</>;
}

function AdminPersonCreate() {
  const [person, setPerson] = useState();

  useEffect(() => {
    // create dummy object
    const a = {
      id: null,
      createdBy: null,
      createdAt: null,
      updatedBy: null,
      updatedAt: null,
      birthday: firebase.newTimestamp(),
      deleted: false,
      firstname: null,
      lastname: null,
      preTitle: null,
      email: null,
      phone: null,
      image: null,
      clubs: [{ club: null, position: null, description: null, order: 0 }],
      types: [""],
    };
    setPerson(a);
  }, []);

  const detail = !!person ? <PersonDetail person={person} /> : null;
  return <>{detail}</>;
}

export {
  AdminArticleCreate,
  AdminArticleUpdate,
  AdminEventCreate,
  AdminEventUpdate,
  AdminPersonCreate,
  AdminPersonUpdate,
};
