import { Menubar } from "primereact/menubar";
import React, { useEffect } from "react";
import { withRouter } from "react-router";

const MenuFF = ({ history }) => {
  const items = [
    {
      label: "News",
      icon: "far fa-envelope fa-lg",
      command: () => {
        history.push("/feuerwehr-news");
      },
    },
    {
      label: "Mitglieder",
      icon: "fas fa-user-friends fa-lg",
      command: () => {
        history.push("/feuerwehr-mitglieder");
      },
    },
    {
      label: "Feuerwehrhaus",
      icon: "fas fa-cubes fa-lg",
      command: () => {
        history.push("/feuerwehr-haus");
      },
    },
  ];

  useEffect(() => {}, []);

  return (
    <div className="p-grid p-fluid dashboard">
      <Menubar model={items} />
    </div>
  );
};

export default withRouter(MenuFF);
