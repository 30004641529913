const environment = `${process.env.NODE_ENV}`;
const dev = "development";

export const articleCollectionProvider = () => {
  if (environment === dev) {
    return "data-test";
  } else {
    return "data";
  }
};

export const eventCollectionProvider = () => {
  if (environment === dev) {
    return "event-test";
  } else {
    return "event";
  }
};

export const personCollectionProvider = () => {
  if (environment === dev) {
    return "person-test";
  } else {
    return "person";
  }
};
