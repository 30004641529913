import { Button } from "primereact/button";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import React, { useState } from "react";
import { isArray } from "./util/ArrayUtils";
import { isDesktop } from "./util/ScreenUtils";

export const Member = (value) => {
  const [layout, setLayout] = useState("list");
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [visible, setVisible] = useState(false);

  const club = (clubs) => {
    return clubs.find((club) => club.club === value.type);
  };

  // showcase
  const renderListItem = (person) => {
    return (
      <div className="p-grid member-list">
        <div className="p-col-2">
          <img
            src={person.image}
            alt="Hier sollte dein Avatar sein"
            className="member-image"
          />
        </div>
        <div className="p-col-2">
          <b>{person.firstname}</b>
        </div>
        <div className="p-col-2">
          <b>{person.lastname}</b>
        </div>
        <div className="p-col-1">
          Funktion: <b>{club(person.clubs).position}</b>
        </div>
        <div className="p-col-4">
          <b>{club(person.clubs).description}</b>
        </div>
        <div className="p-col-1">
          <Button
            icon="pi pi-search"
            onClick={(e) => {
              setSelectedPerson(person);
              setVisible(true);
            }}
          ></Button>
        </div>
      </div>
    );
  };

  const fullname = (person) => {
    if (!person) {
      return "";
    }
    return person.firstname + " " + person.lastname;
  };

  const renderGridItem = (person) => {
    return (
      <div style={{ padding: ".5em" }} className="p-col-12 p-md-3">
        <Panel header={fullname(person)} style={{ textAlign: "center" }}>
          <div
            onClick={(e) => {
              setSelectedPerson(person);
              setVisible(true);
            }}
          >
            <img
              src={person.image}
              alt="Hier sollte dein Avatar sein"
              className="member-image"
            />
          </div>
        </Panel>
      </div>
    );
  };

  const itemTemplate = (person, layout) => {
    if (!person) {
      return;
    }

    if (!isDesktop()) {
      return renderGridItem(person);
    }

    if (layout === "list") return renderListItem(person);
    else if (layout === "grid") return renderGridItem(person);
  };

  const renderPersonDialogContent = () => {
    if (selectedPerson) {
      return (
        <div
          className="p-grid"
          style={{ fontSize: "16px", textAlign: "center", padding: "20px" }}
        >
          <div className="p-col-12" style={{ textAlign: "center" }}>
            <img
              src={selectedPerson.image}
              alt="Hier sollte dein Avatar sein"
              className="member-image"
            />
          </div>

          <div className="p-col-4">Vorname: </div>
          <div className="p-col-8">{selectedPerson.firstname}</div>

          <div className="p-col-4">Nachname: </div>
          <div className="p-col-8">{selectedPerson.lastname}</div>

          <div className="p-col-4">Funktion: </div>
          <div className="p-col-8">{club(selectedPerson.clubs).position}</div>

          <div className="p-col-4">Beschreibung: </div>
          <div className="p-col-8">
            {club(selectedPerson.clubs).description}
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderHeader = () => {
    return (
      <div className="p-grid">
        <div className="p-col-12" style={{ textAlign: "right" }}>
          <DataViewLayoutOptions
            layout={layout}
            onChange={(e) => setLayout(e.value)}
          />
        </div>
      </div>
    );
  };

  const header = renderHeader();

  const result = () => {
    if (!value || !isArray(value.members)) {
      return null;
    }
    return (
      <div className="p-grid p-fluid">
        <DataView
          value={value.members}
          layout={layout}
          header={isDesktop() ? header : null}
          itemTemplate={itemTemplate}
        />

        <Dialog
          header={fullname(selectedPerson)}
          visible={visible}
          modal={true}
          onHide={() => setVisible(false)}
        >
          {renderPersonDialogContent()}
        </Dialog>
      </div>
    );
  };

  return <> {result()}</>;
};
