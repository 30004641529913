import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/list/main.css";
import "@fullcalendar/timegrid/main.css";
import classNames from "classnames";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova-light/theme.css";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import { AppMenu } from "./AppMenu";
import { AppTopbar } from "./AppTopbar";
import Admin from "./components/Admin";
import {
  AdminArticleCreate,
  AdminArticleUpdate,
  AdminEventCreate,
  AdminEventUpdate,
  AdminPersonCreate,
  AdminPersonUpdate
} from "./components/admin/firebaseFunctions";
import Login from "./components/admin/Login";
import { ArticleText } from "./components/ArticleText";
import { Bauernbund } from "./components/bauernbund/Bauernbund";
import { GeneralBauernbund } from "./components/bauernbund/GeneralBauernbund";
import { MemberBauernbund } from "./components/bauernbund/MemberBauernbund";
import { NewsBauernbund } from "./components/bauernbund/NewsBauernbund";
import { BirthDeath } from "./components/birth-death/birth-death";
import { Church } from "./components/church/Church";
import { GeneralChurch } from "./components/church/GeneralChurch";
import { MemberChurch } from "./components/church/MemberChurch";
import { NewsChurch } from "./components/church/NewsChurch";
import { Company } from "./components/company/Company";
import { Contact } from "./components/Contact/Contact";
import PrivateRoute from "./components/context/PrivateRoute";
import { Dashboard } from "./components/Dashboard";
import { Dorferneuerung } from "./components/dorferneuerung/Dorferneuerung";
import { GeneralDorferneuerung } from "./components/dorferneuerung/GeneralDorferneuerung";
import { MemberDorferneuerung } from "./components/dorferneuerung/MemberDorferneuerung";
import { NewsDorferneuerung } from "./components/dorferneuerung/NewsDorferneuerung";
import { Events } from "./components/events/Events";
import { Feuerwehr } from "./components/feuerwehr/Feuerwehr";
import { HouseFF } from "./components/feuerwehr/HouseFF";
import { MemberFF } from "./components/feuerwehr/MemberFF";
import { NewsFF } from "./components/feuerwehr/NewsFF";
import { Gemeinde } from "./components/gemeinde/Gemeinde";
import { GeneralJugend } from "./components/jugend/GeneralJugend";
import { Jugend } from "./components/jugend/Jugend";
import { MemberJugend } from "./components/jugend/MemberJugend";
import { NewsJugend } from "./components/jugend/NewsJugend";
import { GeneralKamerade } from "./components/kamerade/GeneralKamerade";
import { Kamerade } from "./components/kamerade/Kamerade";
import { MemberKamerade } from "./components/kamerade/MemberKamerade";
import { NewsKamerade } from "./components/kamerade/NewsKamerade";
import { GeneralMusik } from "./components/musikverein/GeneralMusik";
import { MemberMusik } from "./components/musikverein/MemberMusik";
import { Musik } from "./components/musikverein/Musik";
import { NewsMusik } from "./components/musikverein/NewsMusik";
import { News } from "./components/News";
import { GeneralSport } from "./components/sportverein/GeneralSport";
import { MemberSport } from "./components/sportverein/MemberSport";
import { NewsSport } from "./components/sportverein/NewsSport";
import { Sport } from "./components/sportverein/Sport";
import firebase from "./firebase";
import "./layout/layout.scss";

class App extends Component {
  constructor() {
    super();
    this.state = {
      layoutMode: "static",
      layoutColorMode: this.isNight() ? "dark" : "light",
      topbarColor: "",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      firebaseInitialized: false,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.createMenu();
  }

  isNight() {
    const today = new Date();
    const hour = today.getHours();
    return hour < 7 || hour > 20;
  }

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  createMenu() {
    this.menu = [
      {
        label: "News",
        icon: "far fa-envelope fa-lg",
        to: "/news",
        command: () => this.setState({ topbarColor: "blue" }),
      },
      {
        label: "Vereine",
        icon: "fas fa-globe-europe fa-lg",
        badge: "8",
        command: () => this.setState({ topbarColor: "black" }),
        items: [
          {
            label: "Feuerwehr",
            icon: "fa fa-fire fa-lg",
            to: "/feuerwehr",
            command: () => this.setState({ topbarColor: "red" }),
          },
          {
            label: "Sportverein",
            icon: "fas fa-futbol",
            to: "/sport-allgemein",
          },
          {
            label: "Musikverein",
            icon: "fas fa-music fa-lg",
            to: "/musik-allgemein",
          },
          {
            label: "Dorferneuerung",
            icon: "fas fa-university fa-lg",
            to: "/dorferneuerung-allgemein",
          },
          {
            label: "Jugend",
            icon: "fas fa-child fa-lg",
            to: "/jugend-allgemein",
          },
          {
            label: "Kirche",
            icon: "fas fa-church fa-lg",
            to: "/kirche",
          },
          {
            label: "Kameradschaftsbund",
            icon: "fas fa-hat-cowboy fa-lg",
            to: "/kamerade",
          },
          {
            label: "Bauernbund",
            icon: "fas fa-tractor fa-lg",
            to: "/bauernbund",
          },
        ],
      },
      {
        label: "Events",
        icon: "far fa-calendar-alt fa-lg",
        to: "/events",
      },
      {
        label: "Betriebe",
        icon: "fas fa-utensils fa-lg",
        to: "/betriebe",
      },
      {
        label: "Gemeinde",
        icon: "fas fa-chess-rook fa-lg",
        to: "/gemeinde",
      },
      {
        label: "Großmeiseldorf",
        icon: "fas fa-home fa-lg",
        command: () => {
          window.location = "/";
        },
      },
      {
        label: "Geburten - Sterbefälle",
        icon: "fas fa-battery-three-quarters fa-lg",
        to: "/geburten-sterbefaelle",
      },
      {
        label: "Kontakt",
        icon: "far fa-id-card fa-lg",
        to: "/kontakt",
      },
      {
        label: "Archiv",
        icon: "far fa-folder fa-lg",
        command: () => {
          window.open("https://archiv.grossmeiseldorf.at", "_blank");
        },
      },
      {
        label: "Admin",
        icon: "fas fa-cogs fa-lg",
        command: () => {
          window.location = "/admin-overview";
        },
      },
    ];
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidMount() {
    firebase.isInitialized().then((val) => {
      this.setState({ firebaseInitialized: true });
    });
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  }

  render() {
    // const logo = "assets/images/logo/logo.png";

    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
    });

    const sidebarClassName = classNames("layout-sidebar", {
      "layout-sidebar-dark": this.state.layoutColorMode === "dark",
      "layout-sidebar-light": this.state.layoutColorMode === "light",
    });

    const topbarClassName = classNames("clearfix", {
      "layout-topbar": this.state.topbarColor === "",
      "layout-topbar-red": this.state.topbarColor === "red",
      "layout-topbar-blue": this.state.topbarColor === "blue",
      "layout-topbar-black": this.state.topbarColor === "black",
    });

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <AppTopbar
          onToggleMenu={this.onToggleMenu}
          styleclass={topbarClassName}
        />

        <div
          ref={(el) => (this.sidebar = el)}
          className={sidebarClassName}
          onClick={this.onSidebarClick}
        >
          {/* <div className="layout-logo">
            <img alt="Logo" src={logo} className="dashboard-image" />
          </div> */}
          {/* <AppProfile /> */}
          <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
        </div>

        <div className="layout-main">
          <Switch>
            <Route exact path="/" component={Dashboard} />

            {/* dorferneuerung */}
            <Route exact path="/dorferneuerung" component={Dorferneuerung} />
            <Route
              exact
              path="/dorferneuerung-news"
              component={NewsDorferneuerung}
            />
            <Route
              exact
              path="/dorferneuerung-allgemein"
              component={GeneralDorferneuerung}
            />
            <Route
              exact
              path="/dorferneuerung-mitglieder"
              component={MemberDorferneuerung}
            />

            {/* news */}
            <Route path="/news" component={News} />
            <Route exact path="/news-article/:id" component={ArticleText} />
            <Route exact path="/events" component={Events} />

            {/* feuerwehr */}
            <Route exact path="/feuerwehr" component={Feuerwehr} />
            <Route exact path="/feuerwehr-news" component={NewsFF} />
            <Route exact path="/feuerwehr-mitglieder" component={MemberFF} />
            <Route exact path="/feuerwehr-haus" component={HouseFF} />

            {/* jugend */}
            <Route exact path="/jugend" component={Jugend} />
            <Route exact path="/jugend-news" component={NewsJugend} />
            <Route exact path="/jugend-allgemein" component={GeneralJugend} />
            <Route exact path="/jugend-mitglieder" component={MemberJugend} />

            {/* sportverein */}
            <Route exact path="/sport" component={Sport} />
            <Route exact path="/sport-news" component={NewsSport} />
            <Route exact path="/sport-allgemein" component={GeneralSport} />
            <Route exact path="/sport-mitglieder" component={MemberSport} />

            {/* musikverein */}
            <Route exact path="/musik" component={Musik} />
            <Route exact path="/musik-news" component={NewsMusik} />
            <Route exact path="/musik-allgemein" component={GeneralMusik} />
            <Route exact path="/musik-mitglieder" component={MemberMusik} />

            {/* betriebe */}
            <Route exact path="/betriebe" component={Company} />

            {/* betriebe */}
            <Route exact path="/geburten-sterbefaelle" component={BirthDeath} />


            {/* kirche */}
            <Route exact path="/kirche" component={Church} />
            <Route exact path="/kirche-news" component={NewsChurch} />
            <Route exact path="/kirche-allgemein" component={GeneralChurch} />
            <Route exact path="/kirche-mitglieder" component={MemberChurch} />

            {/* kameradschaftsbund */}
            <Route exact path="/kamerade" component={Kamerade} />
            <Route exact path="/kamerade-news" component={NewsKamerade} />
            <Route exact path="/kamerade-allgemein" component={GeneralKamerade} />
            <Route exact path="/kamerade-mitglieder" component={MemberKamerade} />

            {/* bauernbund */}
            <Route exact path="/bauernbund" component={Bauernbund} />
            <Route exact path="/bauernbund-news" component={NewsBauernbund} />
            <Route exact path="/bauernbund-allgemein" component={GeneralBauernbund} />
            <Route exact path="/bauernbund-mitglieder" component={MemberBauernbund} />

            {/* gemeinde */}
            <Route exact path="/gemeinde" component={Gemeinde} />

            {/* contact */}
            <Route exact path="/kontakt" component={Contact} />

            <Route exact path="/login" component={Login} />
            <PrivateRoute
              exact
              path="/admin/article-new"
              component={AdminArticleCreate}
            />
            <PrivateRoute
              exact
              path="/admin/article/:id"
              component={AdminArticleUpdate}
            />
            <PrivateRoute
              exact
              path="/admin/event-new"
              component={AdminEventCreate}
            />
            <PrivateRoute
              exact
              path="/admin/event/:id"
              component={AdminEventUpdate}
            />
            <PrivateRoute
              exact
              path="/admin/person-new"
              component={AdminPersonCreate}
            />
            <PrivateRoute
              exact
              path="/admin/person/:id"
              component={AdminPersonUpdate}
            />
            <PrivateRoute path="/admin-overview" exact component={Admin} />
          </Switch>
        </div>

        {/* <AppFooter /> */}

        <div className="layout-mask"></div>
      </div>
    );
  }
}

export default App;
