import { TabPanel, TabView } from "primereact/tabview";
import React from "react";
import { ArticleList } from "./admin/ArticleList";
import EventList from "./admin/EventList";
import Logout from "./admin/Logout";
import { PersonList } from "./admin/PersonList";
import { useStateWithSessionStorageNumber } from "./storage/stateWithSessionStorage";

function Admin(props) {
  const [activeIndex, setActiveIndex] = useStateWithSessionStorageNumber(
    "admin-menue-index"
  );

  const info = `${process.env.REACT_APP_NAME} [v${process.env.REACT_APP_VERSION}]`;
  const environment = `${process.env.NODE_ENV}`;

  return (
    <>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Artikel" leftIcon="pi pi-fw pi-envelope">
          <ArticleList />
        </TabPanel>
        <TabPanel header="Events" leftIcon="pi pi-calendar">
          <EventList />
        </TabPanel>
        <TabPanel header="Personen" leftIcon="pi pi-users">
          <PersonList />
        </TabPanel>
      </TabView>
      <Logout />
      {info} <br />
      {environment}
    </>
  );
}

export default Admin;
