import moment from "moment";
import { Button } from "primereact/button";
import { Column, DataTable } from "primereact/datatable";
import React from "react";
import { Link } from "react-router-dom";
import FireBaseService from "../../service/FirebaseService";
import { dateFormat } from "../enums/enums";

function EventList() {
  const [events, setEvents] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await FireBaseService.findAllEvents();
      setEvents(
        data.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          createdAt: !!doc.data().createdAt
            ? moment(doc.data().createdAt.toDate()).format(dateFormat)
            : null,
          updatedAt: !!doc.data().updatedAt
            ? moment(doc.data().updatedAt.toDate()).format(dateFormat)
            : null,
        }))
      );
    };
    fetchData();
  }, []);

  const actionTemplate = (rowData) => {
    return (
      <div>
        <Link to={`/admin/event/${rowData.id}`}>
          <Button icon="pi pi-search" />
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="p-grid p-fluid">
        <div className="p-col-9 p-lg-9"></div>
        <div className="p-col-3 p-lg-3">
          <Link to={`/admin/event-new`}>
            <Button icon="pi pi-plus-circle" label="Neues Event" />
          </Link>
        </div>
      </div>

      <DataTable
        value={events}
        paginator={true}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
        currentPageReportTemplate="Einträge {first} bis {last} von {totalRecords} werden angezeigt."
        rows={5}
        rowsPerPageOptions={[5, 10, 20]}
        responsive={true}
      >
        <Column field="id" header="ID" />
        <Column field="title" header="Titel" />
        <Column field="createdBy" header="Ersteller" />
        <Column field="createdAt" header="am" style={{ width: "100px" }} />
        <Column field="updatedBy" header="Aktualisiert" />
        <Column field="updatedAt" header="am" style={{ width: "100px" }} />
        <Column body={actionTemplate} style={{ width: "60px" }} />
      </DataTable>
    </>
  );
}

export default EventList;
